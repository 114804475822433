/* 
* Please refrain from styling using this file, this is only used to 
* globally reset stylings that can't really be done anywhere else 
*/

body {
  margin: 0;
  padding: 0;
}

th {
  text-align: left;
}